// extracted by mini-css-extract-plugin
export var lbContainerOuter = "w_rB";
export var lbContainerInner = "w_rC";
export var movingForwards = "w_rD";
export var movingForwardsOther = "w_rF";
export var movingBackwards = "w_rG";
export var movingBackwardsOther = "w_rH";
export var lbImage = "w_rJ";
export var lbOtherImage = "w_rK";
export var prevButton = "w_rL";
export var nextButton = "w_rM";
export var closing = "w_rN";
export var appear = "w_rP";