import React, { useState } from 'react';

import Lightbox from '../../../../Lightbox';
import SeparatorElement from '../../LayoutElements/SeparatorElement';
import ButtonElement from '../../LayoutElements/ButtonElement';
import MapElement from '../../LayoutElements/MapElement';
import QuoteElement from '../../LayoutElements/QuoteElement';
import GalleryElement from '../../LayoutElements/GalleryElement';
import VideoElement from '../../LayoutElements/VideoElement';
import ImageElement from '../../LayoutElements/ImageElement';
import TextElement from '../../LayoutElements/TextElement';
import RowElement from '../../LayoutElements/RowElement';
import AudioElement from '../../LayoutElements/AudioElement';

import * as styles from './styles.module.css';

const getImages = (data, result) => {
  data?.forEach((item) => {
    if (item?.type === 'GALLERY' && item?.lightbox) result.push(...item.data?.map((i) => i?.id));
    else if (item?.type === 'ROW' && item.data?.length > 0) {
      item.data?.forEach((d) => {
        if (d?.data?.length > 0) getImages(d?.data, result);
      });
    }
  });
};

const BlankLayout = ({
  section,
  themeData,
  categoryPathList,
  pagePathList,
  articlePathList,
  filePathList,
  images,
  buttons,
  sectionSlugs,
}) => {
  const [photo, setPhoto] = useState({ photoIndex: 0, isOpen: false });

  const sImages = [];
  getImages(section.data, sImages);

  const handleImageClick = (event) => {
    const photoIndex = sImages.findIndex((id) => id === event.target.dataset.imageid);

    if (photoIndex > -1) {
      setPhoto({
        photoIndex,
        isOpen: true,
      });
    }
  };

  const handleCloseClick = () => {
    setPhoto({
      isOpen: false,
    });
  };

  const handleMoveNextRequest = () => {
    setPhoto({
      ...photo,
      photoIndex: (photo.photoIndex + 1) % sImages.length,
    });
  };

  const handleMovePrevRequest = () => {
    setPhoto({
      ...photo,
      photoIndex: (photo.photoIndex + (sImages.length - 1)) % sImages.length,
    });
  };

  const createText = (item, index, type, componentIndex = null, subIndex, space) => {
    return (
      <TextElement
        item={item}
        componentIndex={componentIndex}
        subIndex={subIndex}
        space={space}
        type={type}
        themeData={themeData}
      />
    );
  };

  const createImage = (item, elementIndex, componentIndex, subIndex, space, rowAlign) => {
    return (
      <ImageElement
        item={item}
        section={section}
        subIndex={subIndex}
        elementIndex={elementIndex}
        space={space}
        colors={themeData?.colors}
        images={images}
        pagePathList={pagePathList}
        articlePathList={articlePathList}
        filePathList={filePathList}
        categoryPathList={categoryPathList}
        rowAlign={rowAlign}
      />
    );
  };

  const createVideo = (item, index, componentIndex, subIndex, space) => {
    return <VideoElement item={item} subIndex={subIndex} space={space} />;
  };

  const createGallery = (gllr, componentIndex, subIndex, subSubIndex, space) => {
    return (
      <GalleryElement
        gllr={gllr}
        section={section}
        componentIndex={componentIndex}
        subIndex={subIndex}
        subSubIndex={subSubIndex}
        space={space}
        colors={themeData?.colors}
        onImageClick={handleImageClick}
        images={images}
      />
    );
  };

  const createQuote = (item, index, componentIndex, subIndex, space) => {
    return (
      <QuoteElement
        item={item}
        index={index}
        componentIndex={componentIndex}
        subIndex={subIndex}
        space={space}
        colors={themeData?.colors}
        createText={createText}
      />
    );
  };

  const createSeparator = (item, index, componentIndex, subIndex, space) => {
    return (
      <SeparatorElement
        item={item}
        section={section}
        componentIndex={componentIndex}
        subIndex={subIndex}
        space={space}
        colors={themeData?.colors}
      />
    );
  };

  const createButton = (item, index, componentIndex, subIndex, space) => {
    return (
      <ButtonElement
        item={item}
        componentIndex={componentIndex}
        subIndex={subIndex}
        space={space}
        themeData={themeData}
        buttons={buttons}
        pagePathList={pagePathList}
        articlePathList={articlePathList}
        filePathList={filePathList}
        categoryPathList={categoryPathList}
        sectionSlugs={sectionSlugs}
      />
    );
  };

  const createMap = (item, index, componentIndex, subIndex, space) => {
    return (
      <MapElement
        item={item}
        section={section}
        index={index}
        componentIndex={componentIndex}
        subIndex={subIndex}
        space={space}
        name={themeData?.name}
      />
    );
  };

  const createAudio = (item, index, componentIndex, subIndex, space) => {
    return <AudioElement item={item} subIndex={subIndex} space={space} />;
  };

  const getElement = (item, index, componentIndex, subIndex, space, rowAlign) => {
    let elem;
    if (item?.type === 'HEADING' || item?.type === 'PARAGRAPH/PARAGRAPH') {
      elem = createText(item, index, false, componentIndex, subIndex, space);
    } else if (item?.type === 'PARAGRAPH/QUOTE') {
      elem = createQuote(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'IMAGE' || item?.type === 'ICON') {
      elem = createImage(item, index, componentIndex, subIndex, space, rowAlign);
    } else if (item?.type === 'VIDEO') {
      elem = createVideo(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'GALLERY') {
      elem = createGallery(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'BUTTON') {
      elem = createButton(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'SEPARATOR') {
      elem = createSeparator(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'MAP') {
      elem = createMap(item, index, componentIndex, subIndex, space);
    } else if (item?.type === 'AUDIO') {
      elem = createAudio(item, index, componentIndex, subIndex, space);
    }

    return elem;
  };

  const align = styles[`align${section?.styles?.align || 'Left'}`];
  const content = [];

  section?.data?.forEach((item, index) => {
    let elem;
    if (item?.type === 'ROW') {
      elem = (
        <RowElement
          key={`${section._id}_Row_${index}`}
          item={item}
          section={section}
          index={index}
          getElement={getElement}
        />
      );
    } else {
      elem = (
        <div key={`${section._id}_Row_${index}`} className={`row ${styles.customRow} ${align}`}>
          {getElement(item, index)}
        </div>
      );
    }
    if (elem) content.push(elem);
  });

  return (
    <div
      className={`${section.styles.full ? 'container-fluid' : 'container'} longarticle`}
      id={`${section._id}_Custom_Main`}
    >
      {sImages.length > 0 && (
        <Lightbox
          mainImageId={sImages[photo.photoIndex]}
          nextImageId={sImages[(photo.photoIndex + 1) % sImages.length]}
          prevImageId={sImages[(photo.photoIndex + (sImages.length - 1)) % sImages.length]}
          onCloseRequest={handleCloseClick}
          onMovePrevRequest={handleMovePrevRequest}
          onMoveNextRequest={handleMoveNextRequest}
          visible={photo.isOpen}
          images={images}
        />
      )}
      {content}
    </div>
  );
};

export default BlankLayout;
